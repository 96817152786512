import $ from "jquery";
import "./Icon.scss";

function onIconClick() {
  if (window.location.hash === "#/")
    $(".main-wrapper")[0].scrollTo({ top: 0 });
  else
    window.location.assign("#/");
}

export default function Icon() {
  return (
    <span className="navbar-brand" onClick={onIconClick}>
      <div className="icon px-0">
          <div className="icon-text">
          <span>&nbsp;CKE</span>
          <span className="hover-to-show long">ISC 44<sup><small>th</small></sup> x&nbsp;</span>
          <span>FGISC&nbsp;</span>
          <span className="hover-to-show">38<sup><small>th</small></sup></span>
          <span className="hover-to-hide">29<sup><small>th</small></sup></span>
        </div>
      </div>
    </span>
  );
}
